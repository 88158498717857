import React from 'react';
import styled from 'styled-components';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle, WatchVideoBannerButton,
} from '../../shared/Banner'
import { PageImage } from '../../shared/PageImage';
import BusinessesSecurityBannerIconSource from './images/businessSecurityBannerIcon.svg';
import { STYLES } from '../../../style';

const StyledBusinessesSecurityBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
`;

const BusinessesSecurityBanner = () => (
  <StyledBusinessesSecurityBanner color={STYLES.PAGE_BUSINESSES_SECURITY_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage src={BusinessesSecurityBannerIconSource} />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>You can trust us</BannerTitle>
        <BannerDescription>
          We understand how important it is for your customers
          to trust you. Hand Carry will uphold that trust to strengthen
          your brand by standing by our promise to deliver better.
        </BannerDescription>
        <WatchVideoBannerButton />
      </BannerContentItem>
    </BannerContent>
  </StyledBusinessesSecurityBanner>
);

export { BusinessesSecurityBanner };