import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Layout } from '../../components/shared/layout';
import { Page } from '../../components/shared/Page';
import { BusinessesSecurityBanner } from '../../components/modules/BusinessesSecurityPage';
import { DownloadMerchantsApp } from '../../components/modules/common/DownloadHandCarryApp';
import { BusinessNavigation } from '../../components/modules/common/BusinessNavigation';
import { LocationProp } from '../../components/shared/props/LocationProp';
import { SecurityDetailsList } from '../../components/modules/BusinessesSecurityPage/SecurityDetailsList'

const StyledBusinessSecurityPage = styled(Layout)`
  text-align: center;
`;

const BusinessSecurityPage = ({
  location: { pathname },
}) => (
  <StyledBusinessSecurityPage headerTransparent>
    <Page>
      <BusinessesSecurityBanner />
      <SecurityDetailsList />
      <BusinessNavigation pathname={pathname} />
      <DownloadMerchantsApp />
    </Page>
  </StyledBusinessSecurityPage>
);

BusinessSecurityPage.propTypes = {
  location: PropTypes.shape(LocationProp).isRequired,
};

export default BusinessSecurityPage;
