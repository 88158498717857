import React from 'react';
import CardPassImageSource from '../images/cardpass.svg';
import ShieldImageSource from '../images/shield_check.svg';
import BandaidImageSource from '../images/band_aid.svg';
import LockImageSource from '../images/lock.svg';
import { TwoColumnList } from '../../../shared/TwoColumnList';

const items = [
  { image: CardPassImageSource, title: 'We know every one of our members', description: 'All our members are required to provide extensive ID for verification. We personally verify each and every Hand Carry member before they are able to deliver.' },
  { image: ShieldImageSource, title: 'Parcel insurance for peace of mind', description: 'Every parcel shipped via Hand Carry is insured for up to $250 USD so you can rest assured that if anything happens you’ll be covered.' },
  { image: BandaidImageSource, title: 'Commuter safety first', description: 'All our members who deliver for Hand Carry are protected by our company’s coverage policy for up to $1m dollars USD in case of any injuries sustained delivering for Hand Carry.' },
  { image: LockImageSource, title: 'Your privacy guaranteed', description: 'You can be assured that all your data is safe. We encrypt all our data with the latest protocols and we don’t store any of your data.' },
];

const SecurityDetailsList = () => (
  <TwoColumnList
    items={items}
  />
);

export { SecurityDetailsList };